@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/elevation';
@use '@sats-group/ui-lib/tokens/light';

.booked-modal {
  $breakpointMedium: 500px;
  $breakpoint: 600px;

  &__top {
    margin-bottom: spacing.$l;
    margin-top: -(spacing.$l);
    margin-left: -(spacing.$m);
    margin-right: -(spacing.$m);
    background: light.$background-secondary-default;
    border-radius: corner-radius.$m corner-radius.$m 0 0;

    @media(min-width: $breakpointMedium) {
      margin-left: -(spacing.$l);
      margin-right: -(spacing.$l);
    }
  }

  &__top-inner {
    padding: spacing.$l 0;
    display: flex;
    gap: spacing.$l;
  }

  &__header {
    margin-bottom: spacing.$s;
  }

  &__top-indicator {
    min-height: 100%;
    width: 16px; // NOTE: Fixed custom width;
    border-radius: 0 corner-radius.$m corner-radius.$m 0;

    &--blue {
      background-color: light.$ge-workouts-pt
    }

    &--green {
      background-color: light.$ge-workouts-other;
    }
  }

  &__text-alternate {
    color: light.$on-surface-primary-alternate;
  }

  &__remove-button-wrapper {
    margin-top: spacing.$s;
    display: flex;
    justify-content: flex-end;
  }

  &__status {
    display: flex;
    gap: spacing.$s;
    margin-top: spacing.$s;
  }

  &__status-sub-texts {
    color: light.$on-surface-primary-alternate;
  }

  &__boot-camp {
    display: flex;
    gap: spacing.$s;
    align-items: center;
  }

  &__boot-camp-icon {
    color: light.$ge-workouts-pt;
  }

  &__icon {
    flex-shrink: 0;
  }
}
